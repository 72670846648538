<template>
  <gw-form-observer
    :dirty="dirty"
    :loading="loading"
    lazy-validation
    @cancel="cancel"
    @save="save"
  >
    <v-tabs v-model="tab" right>
      <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
        {{ $t(slugify(item)) }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, index) in tabItems"
        :key="`tab-content-${index}`"
        eager
      >
        <div class="p-4">
          <component
            :is="item"
            v-model="obModel"
            v-bind="getProps(item)"
            @save="save"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </gw-form-observer>
</template>

<script lang="ts">
import { Customer, CustomerData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

import FormTabSettings from "@/modules/customers/components/tabs/Settings.vue";
import FormTabPayment from "@/modules/customers/components/tabs/Payment.vue";
import FormTabExtra from "@/modules/customers/components/tabs/Extra.vue";
import FormTabBasics from "@/modules/customers/components/tabs/Basics.vue";
import FormTabBranchesForm from "@/modules/companies/components/BranchesForm.vue";
import { dotcase } from "@/plugins/helpers";
import { assign, get } from "lodash";

@Component({
  components: {
    FormTabSettings,
    FormTabBranchesForm,
    FormTabPayment,
    FormTabExtra,
    FormTabBasics,
  },
})
export default class CustomerForm extends Vue {
  @VModel({ type: Object }) obModel!: Partial<CustomerData>;

  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly basic!: boolean;
  @Prop(Boolean) readonly firmOnly!: boolean;
  @Prop(Boolean) readonly requireDgi!: boolean;
  @Prop(Boolean) readonly withBasics!: boolean;
  @Prop(Boolean) readonly dirty!: boolean;

  tab = 0;

  get tabItems() {
    const arTabItems: string[] = [
      "form-tab-settings",
      "form-tab-payment",
      "form-tab-branches-form",
      "form-tab-extra",
    ];

    if (this.withBasics) {
      arTabItems.unshift("form-tab-basics");
    }

    if (this.endCustomer) {
      const iBranchesIndex = arTabItems.indexOf("form-tab-branches-form");
      arTabItems.splice(iBranchesIndex, 1);
      const iExtraIndex = arTabItems.indexOf("form-tab-extra");
      arTabItems.splice(iExtraIndex, 1);
    }

    return arTabItems;
  }

  get endCustomer(): boolean {
    return this.obModel instanceof Customer
      ? this.obModel.get("end_customer", false)
      : get(this.obModel, "end_customer", false);
  }

  save() {
    this.$emit("save", this.obModel);
  }

  cancel() {
    this.$emit("cancel");
  }

  slugify(key: string): string {
    return dotcase(key);
  }

  getProps(sComp: string): Record<string, any> {
    const obProps = {};

    switch (sComp) {
      case "form-tab-settings":
        assign(obProps, { firmOnly: this.firmOnly });
        break;

      case "form-tab-location":
        assign(obProps, { requireDgi: this.requireDgi });
        break;

      case "form-tab-branches-form":
        assign(obProps, { secondary: true });
        break;

      default:
        break;
    }

    return obProps;
  }
}
</script>
